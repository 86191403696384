import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";
import { lightPalette } from "../../palette";
import { SimplePaletteColorOptions } from "@mui/material";

export const MuiAlert: Components<Theme>['MuiAlert'] = {
    styleOverrides: {
        message: {
            fontWeight: 'bold',
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'center'
        },
        root: {
            borderRadius: '0px',
            zIndex: 99
        },
        icon: {
            display: 'flex',
            alignItems: 'center'
        },
        outlined: {
            boxShadow: 'none'
        },
        outlinedSuccess: {
            border: '1px solid',
            borderColor: (lightPalette?.success as SimplePaletteColorOptions)?.dark,
            backgroundColor: (lightPalette?.success as SimplePaletteColorOptions)?.light
        }
    }
}
