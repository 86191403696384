import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiListItemIcon: Components<Theme>['MuiListItemIcon'] = {
    styleOverrides: {
        root: {
            minWidth: 'unset',
            marginRight: '16px'
        }
    }
}
