import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";
import {lightPalette} from "../../palette";

export const MuiAppBar: Components<Theme>['MuiAppBar'] = {
    styleOverrides: {
        root: {
            backgroundColor: lightPalette?.background?.default,
            borderRadius: 0,
            // border: 'none',
            color: lightPalette.text?.primary,
            zIndex: 1201,
            boxShadow: 'none',
            borderBottom: '1px solid #A3141A'
        }
    },
    defaultProps: {

    }
}
