import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiAccordionDetails: Components<Theme>['MuiAccordionDetails'] = {
    styleOverrides: {
        root: {
         padding: 0
        },
    }
}
