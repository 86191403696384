import {createTheme} from "@mui/material";
import {lightPalette} from "./palette";
import {typography} from "./typography";
import {components} from "./Components/components";

export const theme = createTheme({
    palette: lightPalette,
    typography: typography,
    components: components
});
