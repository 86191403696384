import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";
import {lightPalette} from "../../palette";
import { SimplePaletteColorOptions } from "@mui/material";

export const MuiTab: Components<Theme>['MuiTab'] = {
    defaultProps: {
        disableFocusRipple: true,
        disableTouchRipple: true,
    },
    styleOverrides: {
       root: {
            textTransform: 'none',
            height: '32px',
            minHeight: '36px',
            maxHeight: '36px',
            marginBottom: '-1px',
            transition: 'background 0.1s',
            cursor: 'pointer',
            background: 'unset',
            fontSize: '.8rem',
            fontWeight: 'bold',
            borderLeft: '1px solid',
            borderColor: (lightPalette?.info as SimplePaletteColorOptions)?.dark,
            width: '240px',
            display: 'flex',
            paddingLeft: '32px',
            justifyContent: 'flex-start',
            textAlign: 'left',
            '&:first-child': {
                borderColor: 'transparent'
            },
            '&:hover': {
                background: (lightPalette.info as SimplePaletteColorOptions)?.light,
                borderRadius: '8px 8px 0 0',
                borderColor: 'transparent'
            },
            '&.Mui-selected': {
                color: 'rgba(255, 255, 255, 0.9)',
                cursor: 'default',
                borderRadius: '8px 8px 0 0',
                borderColor: 'transparent',
                background: (lightPalette.secondary as SimplePaletteColorOptions)?.main,
           },
           '&.Mui-disabled': {
            borderRadius: '8px 8px 0 0',
            background: (lightPalette.info as SimplePaletteColorOptions)?.light,
        }
       },
    }
}
