import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiFormControl: Components<Theme>['MuiFormControl'] = {
    styleOverrides: {
        root: {
            backgroundColor: 'unset !important'
        }
    }
}
