import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiTableCell: Components<Theme>['MuiTableCell'] = {
    styleOverrides: {
      head: {
         fontWeight: 800
      }
    }
}
