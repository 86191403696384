import React from 'react';
import {ThemeProvider} from "@mui/material";
import {theme} from "./theme";
import { Nunito } from 'next/font/google'

// If loading a variable font, you don't need to specify the font weight
const font = Nunito({ subsets: ['latin'] })

export const ProjectTheme = ({children}: { children: React.ReactNode }) => {

    return (
        <ThemeProvider theme={theme}>
            <main className={font.className}>
                {children}
            </main>
        </ThemeProvider>
    )

}
