import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiInputBase: Components<Theme>['MuiInputBase'] = {
    styleOverrides: {
        root: {
            marginBottom: '0',
            paddingTop: '7px',
            paddingBottom: '7px',

        //    position: 'relative',
        //    backgroundClip: 'padding-box',
        //    border: '1px solid transparent',
        //    boxSizing: 'border-box',


        //    '&:before': {
        //         content: '""',
        //         position: 'absolute',
        //         top: 0,
        //         right: 0,
        //         bottom: 0,
        //         left: 0,
        //         zIndex: -1,
        //         margin: 1,
        //         borderRadius: 'inherit',
        //         background: 'linear-gradient(to right, red, orange)',
        //     }
       },


    }
}
