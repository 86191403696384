import {Theme} from "@mui/material/styles/createTheme";
import {lightPalette} from "../../palette";
import {SimplePaletteColorOptions} from "@mui/material";
import {DataGridComponents} from "@mui/x-data-grid/themeAugmentation";

export const MuiDataGrid: DataGridComponents<Theme>['MuiDataGrid'] = {
    defaultProps: {
        
    },
    styleOverrides: {
        columnHeader: {
            '&:focus': {
                outline: 'none'
            }
        },
        columnHeaderTitle: {
            fontWeight: 800
        },
        columnHeaders: {
          borderBottom: `1px solid ${lightPalette?.divider}`
        },
        row: {
            position: 'relative',
            // borderBottom: `1px solid ${lightPalette?.divider}`,
            '&:hover': {
              backgroundColor: (lightPalette?.primary as SimplePaletteColorOptions).light
          }
        },
        cell: {
            backgroundColor: 'unset !important',
            borderBottom: 'unset',
            '&:focus': {
                outline: 'none'
            },
            '&:focus-within': {
                outline: 'none'
            }
        },
        actionsCell: {
            '&:focus-within': {
                outline: 'none'
            }
        },
        'cell--editing': {
            backgroundColor: 'unset !important',
            padding: '0 10px !important',
            transition: 'background-color 0.1s',
            cursor: 'text',
            '&:hover': {
                backgroundColor: `#e9c1c0 !important`
            },
            '&:focus-within': {
                backgroundColor: '#FFF !important'
            },
        },
        'row--editing': {
            boxShadow: 'unset',
            backgroundColor: `${(lightPalette?.primary as SimplePaletteColorOptions).dark} !important`,
        },
    }
}
