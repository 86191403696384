import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiChip: Components<Theme>['MuiChip'] = {
    styleOverrides: {
        root: {
            // fontWeight: 'bold'
        }
    }
}
