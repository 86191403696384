import {PaletteOptions} from "@mui/material";

export const lightPalette: PaletteOptions = {
    mode: 'light',
    shading: {
        light: '#F9F9F9',
        main: '#F4F4F4',
        dark: '#979797'
    },
    primary: {
        light: '#feefed',
        main: '#A3141A'
    },
    secondary: {
        light: '#D9DFE3',
        main: '#000',
        dark: '#b2bdc7'
    },
    background: {
        paper: '#FFF',
        default: '#FAFAFA'
    },
    success: {
        main: '#51AE58',
        light: '#dcefde',
        dark: '#c6d7c8'
    },
    error: {
        main: '#FF5656FF',
        dark: 'rgb(255, 227, 227)',
        light: 'rgba(163, 20, 26, 0.1)'
    },
    warning: {
        main: '#FFC907',
        light: '#fff4cd',
        dark: '#806504'
    },
    divider: '#E0E0E0',
    text: {
        primary: '#1a1a1a',
        secondary: '#8A8A8E'
    },
    info: {
        main: '#575757',
        light: '#ECECEC',
        dark: '#d5d5d5'
    },
}

export const darkPalette: PaletteOptions = {
    mode: 'dark',
    primary: {
        light: '#feefed',
        main: '#FFF'
    },
    secondary: {
        light: '#D9DFE3',
        main: '#002747',
        dark: '#b2bdc7'
    },
    background: {
        paper: '#2c2c2c',
        default: '#212121'
    },
    success: {
        main: '#51AE58'
    },
    error: {
        main: '#FF5656FF' // Placeholde
    },
    warning: {
        main: '#ff7d56' // Placeholder
    },
    divider: '#E0E0E0',
    text: {
        primary: '#FFF',
        secondary: '#dadada'
    },
    info: {
        main: '#ECECEC',
        light: '#6b6b6b',
        dark: '#565656'
    },
}
