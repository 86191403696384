import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiFormGroup: Components<Theme>['MuiFormGroup'] = {
    styleOverrides: {
        root: {
            width: '100%'
            
       },
       
       
    }
}
