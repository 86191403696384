import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiFilledInput:  Components<Theme>['MuiFilledInput'] = {
    defaultProps:{
        disableUnderline: true,
        hiddenLabel: true,
    },
    styleOverrides:{
        input: {
            paddingTop: '5px',
            paddingBottom: '5px',
        },
        root:{
            borderRadius: '4px',
            marginBottom: 0,
            padding: '12px inherit',
            border: '1px solid rgba(0,0,0,0.15)',
            backgroundColor: '#FFF',
            boxShadow: '2px 2px 0.4rem rgba(0,0,0,0.03)',
            '&:hover': {
                backgroundColor: 'unset !important'
            },
            '&:focus': {
                backgroundColor: 'unset !important'
            },
            '&:active': {
                backgroundColor: 'unset !important'
            }
        },
    }
}
