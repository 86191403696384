import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiDrawer: Components<Theme>['MuiDrawer'] = {
    defaultProps: {
      PaperProps: {
          elevation: 0
      }
    },
    styleOverrides: {
        docked: {
            width: '200px'
        }
    }
}
