import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiInput:  Components<Theme>['MuiInput'] = {
    defaultProps:{
        disableUnderline: true,
    },
    styleOverrides: {
        root: {
            padding: '4px 8px',
            border: 'none',
        }
    }
}
