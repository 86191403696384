'use client'
import React from 'react';
import {CssBaseline} from "@mui/material";
import { SnackbarProvider } from 'notistack';
import { ProjectTheme } from '../../../theme/ProjectTheme'
import { IProjectFunctionalWrapperProps } from './ProjectFunctionalWrapper.types';
import { LicenseInfo, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { SessionProvider } from 'next-auth/react';
import { SnackbarCloseButton } from './SnackbarCloseButton/SnackbarCloseButton';
import enGB from 'date-fns/locale/en-GB';

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY || '');

export const ProjectFunctionalWrapper = ({children}: IProjectFunctionalWrapperProps) => {

    return (
        <SessionProvider>
            <ProjectTheme>
                <CssBaseline />
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                    <SnackbarProvider 
                        maxSnack={3} 
                        autoHideDuration={5000}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} 
                        classes={{ root: 'notistack-positioning' }}
                        style={{
                            minWidth: '180px'
                        }}
                        action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
                    >
                        {children}
                    </SnackbarProvider>
                </LocalizationProvider>
            </ProjectTheme>
        </SessionProvider>
    )

}
