import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
    styleOverrides: {
        root: {
            marginBottom: '0',
        },
        inputRoot: {
            paddingBottom: '5px',
            paddingTop: '5px'
        },
    }
}
