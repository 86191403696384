import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";
import { lightPalette } from "../../palette";

export const MuiTabs: Components<Theme>['MuiTabs'] = {
    defaultProps: {
        // @ts-ignore
        indicatorColor: 'transparent',
        TabIndicatorProps: {
            sx: {
                display: 'none'
            }
        }
    },
    styleOverrides: {
        root: {
            padding: '0px 48px 0 24px',
            borderBottom: 'none',
            minHeight: 'max-content',
            '&:first-child': {
                borderColor: 'transparent'
            }
        },
        flexContainer: {
            gap: '12px'
        },
        scroller: {}
    }
}
