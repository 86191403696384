import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiSelect: Components<Theme>['MuiSelect'] = {
    defaultProps: {
        variant: 'filled'
    },
    styleOverrides: {
        filled: {
            backgroundColor: "#FFF",
            border: 'none',
            borderRadius: '4px'
        },
        standard: {
            paddingBottom: '0',
            '&:focus': {
                backgroundColor: 'unset'
            }
        }
    }
}
