import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";
import type {DataGridComponents} from '@mui/x-data-grid/themeAugmentation';
import type { PickerComponents } from '@mui/x-date-pickers-pro/themeAugmentation';
import {MuiButton} from "./Button/MuiButton";
import {MuiCheckbox} from "./Checkbox/MuiCheckbox";
import {MuiPaper} from "./Paper/MuiPaper";
import {MuiDrawer} from "./Drawer/MuiDrawer";
import {MuiFilledInput} from "./Input/MuiFilledInput";
import {MuiTextField} from "./Input/MuiTextField";
import {MuiListItem} from "./List/MuiListItem";
import {MuiListItemIcon} from "./List/MuiListItemIcon";
import {MuiList} from "./List/MuiList";
import {MuiDataGrid} from "./DataGrid/MuiDataGrid";
import {MuiCssBaseline} from "./CssBaseline/MuiCssBaseline";
import {MuiInputBase} from "./Input/MuiInputBase";
import {MuiTab} from "./Tabs/MuiTab";
import {MuiTabs} from "./Tabs/MuiTabs";
import {MuiAppBar} from "./AppBar/MuiAppBar";
import {MuiInputLabel} from "./Input/MuiInputLabel";
import {MuiFormControl} from "./Input/MuiFormControl";
import {MuiAutocomplete} from "./Input/MuiAutocomplete";
import {MuiFormHelperText} from "./Input/MuiFormHelperText";
import {MuiSelect} from "./Select/MuiSelect";
import {MuiAccordion} from "./Accordion/MuiAccordion";
import {MuiAccordionDetails} from "./Accordion/MuiAccordionDetails";
import {MuiAlert} from "./Alert/MuiAlert";
import { MuiChip } from './Chip/MuiChip';
import { MuiInput } from "./Input/MuiInput";
import { MuiTableCell } from "./Table/MuiTableCell";
import { MuiTooltip } from "./Tooltip/MuiTooltip";
import { MuiFormGroup } from "./Input/MuiFormGroup";

export const components: Components<Omit<Theme, 'components'>> & DataGridComponents<Omit<Theme, 'components'>> & PickerComponents<Omit<Theme, 'components'>> = {
    MuiCssBaseline,
    MuiButton,
    MuiCheckbox,
    MuiDrawer,
    MuiPaper,
    MuiFilledInput,
    MuiInput,
    MuiTextField,
    MuiListItem,
    MuiListItemIcon,
    MuiList,
    MuiDataGrid,
    MuiInputBase,
    MuiTab,
    MuiTabs,
    MuiAppBar,
    MuiInputLabel,
    MuiFormControl,
    MuiAutocomplete,
    MuiFormHelperText,
    MuiSelect,
    MuiAccordion,
    MuiAccordionDetails,
    MuiAlert,
    MuiChip,
    MuiTableCell,
    MuiTooltip,
    MuiFormGroup,
}
