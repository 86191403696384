import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
    styleOverrides: {
        root: {
            marginLeft: '0',
            marginBottom: '6px'
        }
    }
}
