import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";
import {lightPalette} from "../../palette";
import {SimplePaletteColorOptions} from "@mui/material";

export const MuiAccordion: Components<Theme>['MuiAccordion'] = {
    defaultProps: {
        disableGutters: true
    },
    styleOverrides: {
        root: {
            borderRadius: 'none',
            textTransform: 'none',
            '&.Mui-selected': {
                background: (lightPalette.info as SimplePaletteColorOptions).light,
                borderBottom: 'none',
                color: 'unset',
                fontWeight: 'bold'
            }
        },
    }
}
