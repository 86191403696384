import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";
import { SimplePaletteColorOptions } from "@mui/material";
import { lightPalette } from "../../palette";

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
    styleOverrides: {
        tooltip: {
            fontSize: '.9rem',
            backgroundColor: lightPalette.background?.paper,
            color: lightPalette.text?.primary,
            border: '1px solid',
            borderColor: (lightPalette.info as SimplePaletteColorOptions).light,
            minWidth: 'max-content',
            maxWidth: 'unset'
        }
    }
}
