import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
    styleOverrides: `
          html {
            font-size: 14px;
            margin: 0;
            padding: 0;
          }

          a {
            color: inherit;
            text-decoration: inherit;
          }

          body {
            margin: 0;
            padding: 0;
          }

          code {
            background-color: #eee;
            border: 1px solid #e1e1e1;
            padding: 2px;
            border-radius: 4px;
            color: #1e1e1e;
          }

          .row-even {
            background: #F4F4F4
          }

          .MuiDataGrid-editInputCell > .MuiInputBase-input {
            padding: 0 !important;
          }

          @keyframes loadingSpin {
            from { transform: rotate(0deg) }
            to { transform: rotate(360deg) }
          }

          .generic-spin-anim {
            animation: loadingSpin 1.2s linear infinite;
          }

          .notistack-positioning {
            min-width: 180px;
          }

        @keyframes onboardingRowIntro {
            from {
              transform: translateY(-50px);
              opacity: 0;
            }
            to {
              transform: translateY(0px);
              opacity: 1;
            }
        }
        `
}
