import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiList: Components<Theme>['MuiList'] = {
    styleOverrides: {
        root: {
            borderRadius: 'none',
            padding: '12px'
        }
    }
}
