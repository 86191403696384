import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiButton: Components<Theme>['MuiButton'] = {
    styleOverrides: {
        root: {
            textTransform: 'none'
        },
        text: {
            fontWeight: 600
        },
        contained: {
            borderRadius: '4px',
            boxShadow: 'none',
            textTransform: 'none',
            padding: '6px 16px',
            fontSize: '0.9rem',
            '&:hover': {
                backgroundColor: undefined,
                boxShadow: 'none'
            }
        }
    }
}
