import { Close } from '@mui/icons-material';
import { Box, Divider, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

export const SnackbarCloseButton = ({snackbarKey}: { snackbarKey: string | number }) => {

    // TOAST
    const { closeSnackbar } = useSnackbar();

    return (
        <Box display={'flex'} gap={1} alignItems={'center'}>
            <Divider orientation='vertical' flexItem sx={{ opacity: .7 }}/>
            <IconButton onClick={() => closeSnackbar(snackbarKey)} size='small'>
                <Close fontSize='small' sx={{ color: "#FFF" }} />
            </IconButton>
        </Box>
       
    )

}