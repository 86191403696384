import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
    styleOverrides: {
        root: {
            fontSize: '0.9rem',
            marginBottom: '0.5rem',
        }
    }
}
