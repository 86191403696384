import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiTextField: Components<Theme>['MuiTextField'] = {
    defaultProps:{
        variant: 'filled',
        inputProps:{
            style:{

            }
        }
    },
    styleOverrides: {
        root: {
            borderRadius: '4px',
        },
    }
}
