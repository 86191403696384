import {TypographyOptions, TypographyStyle} from "@mui/material/styles/createTypography";
import {Palette} from "@mui/material/styles/createPalette";
import {lightPalette} from "./palette";
import {SimplePaletteColorOptions} from "@mui/material";
import { CSSProperties } from 'react';

// Extracting this out, as currently I can't see any reason to have more than two header sizes based on the current designs
// Anything sub-h2 will just look like h2
const h2Props: TypographyStyle = {
    fontSize: '1.1rem',
    marginBottom: '1rem',
    fontStyle: 'normal',
    lineHeight: '19px',
    fontWeight: 700
}

export interface IExtendedTypographyOptions extends TypographyOptions {
    link: CSSProperties;
}

export const typography: IExtendedTypographyOptions | ((palette: Palette) => TypographyOptions) = {

    // Global modifiers
    fontFamily: "'Noto Sans', sans-serif",
    fontWeightBold: 600,

    // Variant specific
    body1: {
        fontSize: '1rem',
        fontWeight: 400,
        fontStyle: 'normal',
        lineHeight: '16px',
    },
    body2: {
        fontSize: '.85rem',
        fontWeight: 400,
        fontStyle: 'normal',
        lineHeight: '16px',
        color: '#333333',
        display: 'inline'
    },
    bodyGrey: {
        fontSize: '1rem',
        fontWeight: 400,
        fontStyle: 'normal',
        lineHeight: '16px',
        color: lightPalette?.text?.secondary
    },
    h1: {
        fontSize: '3.812rem',
        fontWeight: 600,
        lineHeight: '22px',
        fontStyle: 'normal'
    },
    h2: {
        fontSize: '3.062rem',
        fontStyle: 'normal',
        fontWeight: 600
    },
    h3: {
        fontSize: '2.438rem',
        fontStyle: 'normal',
        fontWeight: 600
    },
    h4: {
        fontSize: '1.562rem',
        fontStyle: 'normal',
        fontWeight: 600
    },
    h5: {
        fontSize: '1.250rem',
        fontStyle: 'normal',
        fontWeight: 600
    },
    h6: {
        fontSize: '1.1rem',
        fontStyle: 'normal',
        fontWeight: 600
    },
    subtitle1: {
        fontSize: '1rem',
        fontWeight: 600,
        fontStyle: 'normal',
        color: lightPalette.text?.primary
    },
    subtitle2: {
        fontSize: '1.1rem',
        fontWeight: 500,
        lineHeight: '24px',
        fontStyle: 'normal',
        color: lightPalette.text?.secondary
    },
    button: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '20px',
        fontStyle: 'normal'
    },
    caption: {
        display: 'inline',
        color: lightPalette.text?.secondary
    },
    link: {
        color: (lightPalette.primary as SimplePaletteColorOptions)?.main,
        fontWeight: 800,
        cursor: 'pointer'
    },
} as IExtendedTypographyOptions
