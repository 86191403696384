import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";
import { lightPalette } from '../../palette';

export const MuiPaper: Components<Theme>['MuiPaper'] = {
    styleOverrides: {
        root: {
            borderRadius: '3px',
        },
        outlined: {
            borderRight: '1px solid #E6E6E6'
        },
        elevation: {
            boxShadow: '0px 4px 4px rgba(0,0,0,0.15)'
        },
        elevation2: {
            backgroundColor: lightPalette.background?.default,
        },
        elevation3: {
            backgroundColor: '#dcdcdc'
        }
    }
}
