import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material/styles/createTheme";

export const MuiListItem: Components<Theme>['MuiListItem'] = {
    styleOverrides: {
        root: {
            padding: '16px 16px 16px 24px',
            cursor: 'pointer',
            margin: '6px 0',
            '&:hover': {
                backgroundColor: '#f6f6f6'
            }
        }
    }
}
